@import "https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap";
*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

.container {
  width: 100%;
}

@media (width >= 474px) {
  .container {
    max-width: 474px;
  }
}

@media (width >= 744px) {
  .container {
    max-width: 744px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1440px) {
  .container {
    max-width: 1440px;
  }
}

#app .sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

#app .visible {
  visibility: visible;
}

#app .invisible {
  visibility: hidden;
}

#app .static {
  position: static;
}

#app .fixed {
  position: fixed;
}

#app .absolute {
  position: absolute;
}

#app .relative {
  position: relative;
}

#app .sticky {
  position: sticky;
}

#app .inset-0 {
  inset: 0;
}

#app .inset-x-60 {
  left: 15rem;
  right: 15rem;
}

#app .inset-y-0 {
  top: 0;
  bottom: 0;
}

#app .bottom-0 {
  bottom: 0;
}

#app .bottom-3 {
  bottom: .75rem;
}

#app .bottom-\[56px\] {
  bottom: 56px;
}

#app .left-0 {
  left: 0;
}

#app .left-1 {
  left: .25rem;
}

#app .right-0 {
  right: 0;
}

#app .right-2 {
  right: .5rem;
}

#app .top-0 {
  top: 0;
}

#app .top-1\.5 {
  top: .375rem;
}

#app .top-2 {
  top: .5rem;
}

#app .top-20 {
  top: 5rem;
}

#app .top-\[64px\] {
  top: 64px;
}

#app .z-0 {
  z-index: 0;
}

#app .z-10 {
  z-index: 10;
}

#app .z-20 {
  z-index: 20;
}

#app .z-40 {
  z-index: 40;
}

#app .z-50 {
  z-index: 50;
}

#app .z-\[999\] {
  z-index: 999;
}

#app .m-0 {
  margin: 0;
}

#app .m-1 {
  margin: .25rem;
}

#app .m-4 {
  margin: 1rem;
}

#app .m-auto {
  margin: auto;
}

#app .mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

#app .mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

#app .mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

#app .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

#app .my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

#app .my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

#app .my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

#app .my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

#app .my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

#app .my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

#app .my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

#app .-mb-px {
  margin-bottom: -1px;
}

#app .-ml-1 {
  margin-left: -.25rem;
}

#app .-ml-2 {
  margin-left: -.5rem;
}

#app .-mr-2 {
  margin-right: -.5rem;
}

#app .-mt-12 {
  margin-top: -3rem;
}

#app .-mt-2 {
  margin-top: -.5rem;
}

#app .mb-0 {
  margin-bottom: 0;
}

#app .mb-0\.5 {
  margin-bottom: .125rem;
}

#app .mb-1 {
  margin-bottom: .25rem;
}

#app .mb-1\.5 {
  margin-bottom: .375rem;
}

#app .mb-10 {
  margin-bottom: 2.5rem;
}

#app .mb-12 {
  margin-bottom: 3rem;
}

#app .mb-2 {
  margin-bottom: .5rem;
}

#app .mb-2\.5 {
  margin-bottom: .625rem;
}

#app .mb-3 {
  margin-bottom: .75rem;
}

#app .mb-4 {
  margin-bottom: 1rem;
}

#app .mb-5 {
  margin-bottom: 1.25rem;
}

#app .mb-6 {
  margin-bottom: 1.5rem;
}

#app .mb-8 {
  margin-bottom: 2rem;
}

#app .me-3 {
  margin-inline-end: .75rem;
}

#app .ml-0 {
  margin-left: 0;
}

#app .ml-1 {
  margin-left: .25rem;
}

#app .ml-2 {
  margin-left: .5rem;
}

#app .ml-4 {
  margin-left: 1rem;
}

#app .ml-8 {
  margin-left: 2rem;
}

#app .ml-auto {
  margin-left: auto;
}

#app .mr-0 {
  margin-right: 0;
}

#app .mr-1 {
  margin-right: .25rem;
}

#app .mr-2 {
  margin-right: .5rem;
}

#app .mr-3 {
  margin-right: .75rem;
}

#app .mr-4 {
  margin-right: 1rem;
}

#app .mr-5 {
  margin-right: 1.25rem;
}

#app .mt-0 {
  margin-top: 0;
}

#app .mt-0\.5 {
  margin-top: .125rem;
}

#app .mt-1 {
  margin-top: .25rem;
}

#app .mt-10 {
  margin-top: 2.5rem;
}

#app .mt-2 {
  margin-top: .5rem;
}

#app .mt-2\.5 {
  margin-top: .625rem;
}

#app .mt-3 {
  margin-top: .75rem;
}

#app .mt-3\.5 {
  margin-top: .875rem;
}

#app .mt-4 {
  margin-top: 1rem;
}

#app .mt-6 {
  margin-top: 1.5rem;
}

#app .mt-8 {
  margin-top: 2rem;
}

#app .block {
  display: block;
}

#app .inline-block {
  display: inline-block;
}

#app .inline {
  display: inline;
}

#app .flex {
  display: flex;
}

#app .inline-flex {
  display: inline-flex;
}

#app .table {
  display: table;
}

#app .grid {
  display: grid;
}

#app .hidden {
  display: none;
}

#app .h-10 {
  height: 2.5rem;
}

#app .h-12 {
  height: 3rem;
}

#app .h-20 {
  height: 5rem;
}

#app .h-3 {
  height: .75rem;
}

#app .h-4 {
  height: 1rem;
}

#app .h-40 {
  height: 10rem;
}

#app .h-48 {
  height: 12rem;
}

#app .h-5 {
  height: 1.25rem;
}

#app .h-52 {
  height: 13rem;
}

#app .h-6 {
  height: 1.5rem;
}

#app .h-60 {
  height: 15rem;
}

#app .h-64 {
  height: 16rem;
}

#app .h-8 {
  height: 2rem;
}

#app .h-\[16px\] {
  height: 16px;
}

#app .h-\[30px\] {
  height: 30px;
}

#app .h-\[52\] {
  height: 52px;
}

#app .h-\[72px\] {
  height: 72px;
}

#app .h-\[90\%\] {
  height: 90%;
}

#app .h-\[90vh\] {
  height: 90vh;
}

#app .h-\[95vh\] {
  height: 95vh;
}

#app .h-\[96\%\] {
  height: 96%;
}

#app .h-\[calc\(100\%-32px\)\] {
  height: calc(100% - 32px);
}

#app .h-\[calc\(100\%-40px\)\] {
  height: calc(100% - 40px);
}

#app .h-full {
  height: 100%;
}

#app .h-screen {
  height: 100vh;
}

#app .max-h-36 {
  max-height: 9rem;
}

#app .max-h-40 {
  max-height: 10rem;
}

#app .max-h-48 {
  max-height: 12rem;
}

#app .max-h-\[400px\] {
  max-height: 400px;
}

#app .max-h-\[calc\(100vh-200px\)\] {
  max-height: calc(100vh - 200px);
}

#app .min-h-96 {
  min-height: 24rem;
}

#app .min-h-\[44px\] {
  min-height: 44px;
}

#app .min-h-screen {
  min-height: 100vh;
}

#app .min-h-screen-minus-100 {
  min-height: calc(100vh - 100px);
}

#app .w-1\/3 {
  width: 33.3333%;
}

#app .w-1\/5 {
  width: 20%;
}

#app .w-1\/6 {
  width: 16.6667%;
}

#app .w-10 {
  width: 2.5rem;
}

#app .w-11\/12 {
  width: 91.6667%;
}

#app .w-2\/3 {
  width: 66.6667%;
}

#app .w-2\/5 {
  width: 40%;
}

#app .w-20 {
  width: 5rem;
}

#app .w-24 {
  width: 6rem;
}

#app .w-28 {
  width: 7rem;
}

#app .w-3 {
  width: .75rem;
}

#app .w-3\/5 {
  width: 60%;
}

#app .w-36 {
  width: 9rem;
}

#app .w-4 {
  width: 1rem;
}

#app .w-40 {
  width: 10rem;
}

#app .w-48 {
  width: 12rem;
}

#app .w-5 {
  width: 1.25rem;
}

#app .w-5\/12 {
  width: 41.6667%;
}

#app .w-6 {
  width: 1.5rem;
}

#app .w-7\/12 {
  width: 58.3333%;
}

#app .w-72 {
  width: 18rem;
}

#app .w-8 {
  width: 2rem;
}

#app .w-96 {
  width: 24rem;
}

#app .w-\[172px\] {
  width: 172px;
}

#app .w-\[30px\] {
  width: 30px;
}

#app .w-\[36px\] {
  width: 36px;
}

#app .w-\[400px\] {
  width: 400px;
}

#app .w-\[90vw\] {
  width: 90vw;
}

#app .w-\[95vw\] {
  width: 95vw;
}

#app .w-auto {
  width: auto;
}

#app .w-fit {
  width: fit-content;
}

#app .w-full {
  width: 100%;
}

#app .min-w-\[150px\] {
  min-width: 150px;
}

#app .min-w-\[200px\] {
  min-width: 200px;
}

#app .min-w-\[300px\] {
  min-width: 300px;
}

#app .min-w-\[6rem\] {
  min-width: 6rem;
}

#app .min-w-full {
  min-width: 100%;
}

#app .max-w-2xl {
  max-width: 42rem;
}

#app .max-w-80 {
  max-width: 20rem;
}

#app .max-w-\[67\%\] {
  max-width: 67%;
}

#app .max-w-full {
  max-width: 100%;
}

#app .max-w-lg {
  max-width: 32rem;
}

#app .max-w-md {
  max-width: 28rem;
}

#app .max-w-sm {
  max-width: 24rem;
}

#app .max-w-xl {
  max-width: 36rem;
}

#app .flex-1 {
  flex: 1;
}

#app .flex-shrink-0 {
  flex-shrink: 0;
}

#app .flex-grow {
  flex-grow: 1;
}

#app .table-fixed {
  table-layout: fixed;
}

#app .border-collapse {
  border-collapse: collapse;
}

#app .transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

#app .animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

#app .animate-spin {
  animation: 1s linear infinite spin;
}

#app .cursor-not-allowed {
  cursor: not-allowed;
}

#app .cursor-pointer {
  cursor: pointer;
}

#app .resize {
  resize: both;
}

#app .list-disc {
  list-style-type: disc;
}

#app .list-none {
  list-style-type: none;
}

#app .appearance-none {
  appearance: none;
}

#app .grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

#app .grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

#app .flex-row {
  flex-direction: row;
}

#app .flex-col {
  flex-direction: column;
}

#app .flex-col-reverse {
  flex-direction: column-reverse;
}

#app .flex-wrap {
  flex-wrap: wrap;
}

#app .items-start {
  align-items: flex-start;
}

#app .items-end {
  align-items: flex-end;
}

#app .items-center {
  align-items: center;
}

#app .justify-start {
  justify-content: flex-start;
}

#app .justify-end {
  justify-content: flex-end;
}

#app .justify-center {
  justify-content: center;
}

#app .justify-between {
  justify-content: space-between;
}

#app .justify-around {
  justify-content: space-around;
}

#app .gap-0 {
  gap: 0;
}

#app .gap-10 {
  gap: 2.5rem;
}

#app .gap-2 {
  gap: .5rem;
}

#app .gap-4 {
  gap: 1rem;
}

#app .gap-8 {
  gap: 2rem;
}

#app :is(.space-x-2 > :not([hidden]) ~ :not([hidden])) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

#app :is(.divide-y > :not([hidden]) ~ :not([hidden])) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

#app :is(.divide-gray-200 > :not([hidden]) ~ :not([hidden])) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity, 1));
}

#app .self-center {
  align-self: center;
}

#app .overflow-auto {
  overflow: auto;
}

#app .overflow-hidden {
  overflow: hidden;
}

#app .overflow-x-auto {
  overflow-x: auto;
}

#app .overflow-y-auto {
  overflow-y: auto;
}

#app .truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

#app .whitespace-nowrap {
  white-space: nowrap;
}

#app .whitespace-pre-wrap {
  white-space: pre-wrap;
}

#app .break-words {
  overflow-wrap: break-word;
}

#app .break-all {
  word-break: break-all;
}

#app .rounded {
  border-radius: .25rem;
}

#app .rounded-2xl {
  border-radius: 1rem;
}

#app .rounded-\[6px\] {
  border-radius: 6px;
}

#app .rounded-full {
  border-radius: 9999px;
}

#app .rounded-lg {
  border-radius: .5rem;
}

#app .rounded-md {
  border-radius: .375rem;
}

#app .rounded-xl {
  border-radius: .75rem;
}

#app .rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

#app .rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

#app .rounded-br-full {
  border-bottom-right-radius: 9999px;
}

#app .rounded-tr-full {
  border-top-right-radius: 9999px;
}

#app .border {
  border-width: 1px;
}

#app .border-2 {
  border-width: 2px;
}

#app .border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

#app .border-b {
  border-bottom-width: 1px;
}

#app .border-b-2 {
  border-bottom-width: 2px;
}

#app .border-l {
  border-left-width: 1px;
}

#app .border-r {
  border-right-width: 1px;
}

#app .border-t {
  border-top-width: 1px;
}

#app .border-solid {
  border-style: solid;
}

#app .border-dashed {
  border-style: dashed;
}

#app .border-dotted {
  border-style: dotted;
}

#app .border-\[\#24306099\] {
  border-color: #24306099;
}

#app .border-\[\#E1E4EF\] {
  --tw-border-opacity: 1;
  border-color: rgb(225 228 239 / var(--tw-border-opacity, 1));
}

#app .border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity, 1));
}

#app .border-catelina-blue {
  --tw-border-opacity: 1;
  border-color: rgb(36 48 96 / var(--tw-border-opacity, 1));
}

#app .border-catelina-blue\/20 {
  border-color: #24306033;
}

#app .border-catelina-blue\/60 {
  border-color: #24306099;
}

#app .border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity, 1));
}

#app .border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}

#app .border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
}

#app .border-light-purple {
  --tw-border-opacity: 1;
  border-color: rgb(171 170 222 / var(--tw-border-opacity, 1));
}

#app .border-neutral-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 229 229 / var(--tw-border-opacity, 1));
}

#app .border-neutral-300 {
  --tw-border-opacity: 1;
  border-color: rgb(212 212 212 / var(--tw-border-opacity, 1));
}

#app .border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgb(252 165 165 / var(--tw-border-opacity, 1));
}

#app .border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity, 1));
}

#app .border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity, 1));
}

#app .border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgb(220 38 38 / var(--tw-border-opacity, 1));
}

#app .border-slate-300 {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity, 1));
}

#app .border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}

#app .bg-\[\#E1E4EF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(225 228 239 / var(--tw-bg-opacity, 1));
}

#app .bg-\[\#FAFAFA\] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity, 1));
}

#app .bg-\[\#FFD970\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 217 112 / var(--tw-bg-opacity, 1));
}

#app .bg-\[\#FFF3F6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 243 246 / var(--tw-bg-opacity, 1));
}

#app .bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
}

#app .bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity, 1));
}

#app .bg-catelina-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(36 48 96 / var(--tw-bg-opacity, 1));
}

#app .bg-catelina-blue\/75 {
  background-color: #243060bf;
}

#app .bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}

#app .bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}

#app .bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1));
}

#app .bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}

#app .bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
}

#app .bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

#app .bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity, 1));
}

#app .bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity, 1));
}

#app .bg-grey-primary\/75 {
  background-color: #3b3b3bbf;
}

#app .bg-neutral-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity, 1));
}

#app .bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 232 255 / var(--tw-bg-opacity, 1));
}

#app .bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity, 1));
}

#app .bg-red-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 165 165 / var(--tw-bg-opacity, 1));
}

#app .bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity, 1));
}

#app .bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity, 1));
}

#app .bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity, 1));
}

#app .bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity, 1));
}

#app .bg-slate-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity, 1));
}

#app .bg-transparent {
  background-color: #0000;
}

#app .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

#app .bg-white-smoke {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity, 1));
}

#app .bg-opacity-100 {
  --tw-bg-opacity: 1;
}

#app .bg-opacity-75 {
  --tw-bg-opacity: .75;
}

#app .object-contain {
  object-fit: contain;
}

#app .object-cover {
  object-fit: cover;
}

#app .p-0 {
  padding: 0;
}

#app .p-1 {
  padding: .25rem;
}

#app .p-2 {
  padding: .5rem;
}

#app .p-3 {
  padding: .75rem;
}

#app .p-4 {
  padding: 1rem;
}

#app .p-6 {
  padding: 1.5rem;
}

#app .p-8 {
  padding: 2rem;
}

#app .px-0 {
  padding-left: 0;
  padding-right: 0;
}

#app .px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

#app .px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

#app .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

#app .px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

#app .px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

#app .px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

#app .py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

#app .py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

#app .py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

#app .py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

#app .py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

#app .py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

#app .py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

#app .py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

#app .py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

#app .py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

#app .py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

#app .pb-10 {
  padding-bottom: 2.5rem;
}

#app .pb-16 {
  padding-bottom: 4rem;
}

#app .pb-2 {
  padding-bottom: .5rem;
}

#app .pb-3\.5 {
  padding-bottom: .875rem;
}

#app .pb-4 {
  padding-bottom: 1rem;
}

#app .pb-6 {
  padding-bottom: 1.5rem;
}

#app .pb-\[30px\] {
  padding-bottom: 30px;
}

#app .pl-1 {
  padding-left: .25rem;
}

#app .pl-3 {
  padding-left: .75rem;
}

#app .pl-4 {
  padding-left: 1rem;
}

#app .pl-6 {
  padding-left: 1.5rem;
}

#app .pl-8 {
  padding-left: 2rem;
}

#app .pl-\[20px\] {
  padding-left: 20px;
}

#app .pr-1 {
  padding-right: .25rem;
}

#app .pr-12 {
  padding-right: 3rem;
}

#app .pr-2 {
  padding-right: .5rem;
}

#app .pr-4 {
  padding-right: 1rem;
}

#app .pr-8 {
  padding-right: 2rem;
}

#app .pr-\[20px\] {
  padding-right: 20px;
}

#app .pt-1 {
  padding-top: .25rem;
}

#app .pt-2 {
  padding-top: .5rem;
}

#app .pt-4 {
  padding-top: 1rem;
}

#app .pt-5 {
  padding-top: 1.25rem;
}

#app .pt-8 {
  padding-top: 2rem;
}

#app .pt-\[64px\] {
  padding-top: 64px;
}

#app .text-left {
  text-align: left;
}

#app .text-center {
  text-align: center;
}

#app .font-body {
  font-family: Poppins;
}

#app .text-\[12px\] {
  font-size: 12px;
}

#app .text-\[16px\] {
  font-size: 16px;
}

#app .text-\[20px\] {
  font-size: 20px;
}

#app .text-\[24px\] {
  font-size: 24px;
}

#app .text-base {
  font-size: 16px;
}

#app .text-base-2 {
  font-size: 18px;
}

#app .text-lg {
  font-size: 20px;
}

#app .text-sm {
  font-size: 14px;
}

#app .text-xl {
  font-size: 1.25rem;
}

#app .text-xsm {
  font-size: 12px;
}

#app .text-xxsm {
  font-size: 10px;
}

#app .font-bold {
  font-weight: 700;
}

#app .font-light {
  font-weight: 300;
}

#app .font-medium {
  font-weight: 500;
}

#app .font-normal {
  font-weight: 400;
}

#app .font-semibold {
  font-weight: 600;
}

#app .capitalize {
  text-transform: capitalize;
}

#app .italic {
  font-style: italic;
}

#app .leading-3 {
  line-height: .75rem;
}

#app .leading-4 {
  line-height: 1rem;
}

#app .leading-5 {
  line-height: 1.25rem;
}

#app .leading-6 {
  line-height: 1.5rem;
}

#app .leading-\[14px\] {
  line-height: 14px;
}

#app .leading-normal {
  line-height: 1.5;
}

#app .leading-relaxed {
  line-height: 1.625;
}

#app .leading-tight {
  line-height: 1.25;
}

#app .tracking-wider {
  letter-spacing: .05em;
}

#app .tracking-widest {
  letter-spacing: .1em;
}

#app .text-\[\#243460\] {
  --tw-text-opacity: 1;
  color: rgb(36 52 96 / var(--tw-text-opacity, 1));
}

#app .text-\[white\] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

#app .text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

#app .text-catelina-blue {
  --tw-text-opacity: 1;
  color: rgb(36 48 96 / var(--tw-text-opacity, 1));
}

#app .text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}

#app .text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

#app .text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

#app .text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

#app .text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity, 1));
}

#app .text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity, 1));
}

#app .text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity, 1));
}

#app .text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity, 1));
}

#app .text-grey-primary {
  --tw-text-opacity: 1;
  color: rgb(59 59 59 / var(--tw-text-opacity, 1));
}

#app .text-lime-600 {
  --tw-text-opacity: 1;
  color: rgb(101 163 13 / var(--tw-text-opacity, 1));
}

#app .text-neutral-900 {
  --tw-text-opacity: 1;
  color: rgb(23 23 23 / var(--tw-text-opacity, 1));
}

#app .text-purple-800 {
  --tw-text-opacity: 1;
  color: rgb(107 33 168 / var(--tw-text-opacity, 1));
}

#app .text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity, 1));
}

#app .text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity, 1));
}

#app .text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity, 1));
}

#app .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

#app .underline {
  text-decoration-line: underline;
}

#app .no-underline {
  text-decoration-line: none;
}

#app .opacity-25 {
  opacity: .25;
}

#app .opacity-30 {
  opacity: .3;
}

#app .opacity-50 {
  opacity: .5;
}

#app .opacity-75 {
  opacity: .75;
}

#app .shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#app .shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#app .shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#app .shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#app .shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#app .shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#app .shadow-gray-300 {
  --tw-shadow-color: #d1d5db;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

#app .ring-catelina-blue {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(36 48 96 / var(--tw-ring-opacity, 1));
}

#app .ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

#app .blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

#app .blur-sm {
  --tw-blur: blur(4px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

#app .drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

#app .filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

#app .transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

#app .transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

#app .duration-300 {
  transition-duration: .3s;
}

#app .ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@font-face {
  font-family: Poppins;
  src: url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap") format("woff");
  font-weight: normal;
  font-style: normal;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: Poppins, IBM Plex Sans, Arial, Helvetica, sans-serif;
}

.domain-tabs .MuiTabs-scrollButtons.Mui-disabled {
  opacity: .3;
}

.domain-tabs .MuiTabs-scrollButtons:first-child {
  margin-left: -15px;
}

.MuiMenuItem-gutters.active {
  color: #243060;
  font-weight: 700;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

figcaption, figure, main {
  display: block;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
}

abbr[title] {
  text-decoration: underline;
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: inherit;
  font-weight: bolder;
}

#app .App {
  width: 100%;
  margin: auto;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  color: #000;
  background-color: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: Poppins, sans-serif;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
  display: inline-block;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details, menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template, [hidden] {
  display: none;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #fff;
}

hr {
  background: #e9edf3;
  border: 0;
  height: 1px;
  margin-top: 24px;
  margin-bottom: 24px;
  display: block;
}

ul, ol {
  margin-top: 0;
  margin-bottom: 24px;
  padding-left: 24px;
}

ul {
  list-style: outside;
}

ol {
  list-style: decimal;
}

li > ul, li > ol {
  margin-bottom: 0;
}

dl {
  margin-top: 0;
  margin-bottom: 24px;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 24px;
  margin-left: 24px;
}

img {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

figure {
  margin: 24px 0;
}

figcaption {
  padding: 8px 0;
  font-size: 16px;
  line-height: 24px;
}

img, svg {
  display: block;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 24px;
}

tr {
  border-bottom: 1px solid #e9edf3;
}

th {
  text-align: left;
}

th, td {
  padding: 10px 16px;
}

html {
  font-size: 20px;
  line-height: 30px;
}

body {
  color: #6b7a90;
  font-size: 1rem;
}

body, button, input, select, textarea {
  font-family: Poppins, sans-serif;
}

a {
  color: #535fd7;
  text-decoration: none;
}

a:hover, a:active {
  outline: 0;
  text-decoration: underline;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  clear: both;
  color: #182538;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 600;
}

h1, .h1 {
  letter-spacing: -.1px;
  font-size: 38px;
  line-height: 48px;
}

@media (width >= 641px) {
  h1, .h1 {
    letter-spacing: 0;
    font-size: 44px;
    line-height: 54px;
  }
}

h2, .h2 {
  letter-spacing: -.1px;
  font-size: 32px;
  line-height: 42px;
}

@media (width >= 641px) {
  h2, .h2 {
    letter-spacing: -.1px;
    font-size: 38px;
    line-height: 48px;
  }
}

h3, .h3, blockquote {
  letter-spacing: -.1px;
  font-size: 24px;
  line-height: 34px;
}

h4, h5, h6, .h4, .h5, .h6 {
  letter-spacing: -.1px;
  font-size: 20px;
  line-height: 30px;
}

@media (width <= 640px) {
  .h1-mobile {
    letter-spacing: -.1px;
    font-size: 38px;
    line-height: 48px;
  }

  .h2-mobile {
    letter-spacing: -.1px;
    font-size: 32px;
    line-height: 42px;
  }

  .h3-mobile {
    letter-spacing: -.1px;
    font-size: 24px;
    line-height: 34px;
  }

  .h4-mobile, .h5-mobile, .h6-mobile {
    letter-spacing: -.1px;
    font-size: 20px;
    line-height: 30px;
  }
}

.text-light, .text-light a {
  color: #8595ae;
}

.text-light h1, .text-light h2, .text-light h3, .text-light h4, .text-light h5, .text-light h6, .text-light .h1, .text-light .h2, .text-light .h3, .text-light .h4, .text-light .h5, .text-light .h6 {
  color: #fff !important;
}

h1, h2, .h1, .h2 {
  margin-top: 48px;
  margin-bottom: 16px;
}

h3, .h3 {
  margin-top: 36px;
  margin-bottom: 12px;
}

h4, h5, h6, .h4, .h5, .h6 {
  margin-top: 24px;
  margin-bottom: 4px;
}

p {
  margin-top: 0;
  margin-bottom: 24px;
}

dfn, cite, em, i {
  font-style: italic;
}

blockquote {
  color: #6b7a90;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 24px;
  font-style: italic;
}

blockquote:before {
  content: "“";
}

blockquote:after {
  content: "”";
}

blockquote p {
  display: inline;
}

address {
  color: #6b7a90;
  border: 1px solid #e9edf3;
  border-width: 1px 0;
  margin: 0 0 24px;
  padding: 24px 0;
}

pre, pre h1, pre h2, pre h3, pre h4, pre h5, pre h6, pre .h1, pre .h2, pre .h3, pre .h4, pre .h5, pre .h6 {
  font-family: "Courier 10 Pitch", Courier, monospace;
}

pre, code, kbd, tt, var {
  background: #f8f9fb;
}

pre {
  max-width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 24px;
  font-size: 16px;
  line-height: 24px;
  overflow: auto;
}

code, kbd, tt, var {
  padding: 2px 4px;
  font-family: Monaco, Consolas, Andale Mono, DejaVu Sans Mono, monospace;
  font-size: 16px;
}

abbr, acronym {
  cursor: help;
}

mark, ins {
  text-decoration: none;
}

small {
  letter-spacing: -.1px;
  font-size: 18px;
  line-height: 27px;
}

b, strong {
  font-weight: 600;
}

button, input, select, textarea, label {
  font-size: 16px;
  line-height: 30px;
}

.container, .container-sm {
  width: 100%;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
}

@media (width >= 481px) {
  .container, .container-sm {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.container {
  max-width: 1128px;
}

.container-sm {
  max-width: 848px;
}

.container .container-sm {
  max-width: 800px;
  padding-left: 0;
  padding-right: 0;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  overflow: hidden;
  word-wrap: normal !important;
  position: absolute !important;
}

.screen-reader-text:focus {
  letter-spacing: 0;
  text-transform: uppercase;
  z-index: 100000;
  background-color: #fff;
  border: none;
  border-radius: 2px;
  width: auto;
  height: auto;
  padding: 16px 32px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-decoration: none;
  display: block;
  top: 8px;
  left: 8px;
  box-shadow: 0 0 2px 2px #0009;
  clip: auto !important;
  color: #535fd7 !important;
}

.list-reset {
  padding: 0;
  list-style: none;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-primary {
  color: #535fd7;
}

.text-secondary {
  color: #61effd;
}

.has-top-divider {
  position: relative;
}

.has-top-divider:before {
  content: "";
  background: #e9edf3;
  width: 100%;
  height: 1px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.has-bottom-divider {
  position: relative;
}

.has-bottom-divider:after {
  content: "";
  background: #e9edf3;
  width: 100%;
  height: 1px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mr-0 {
  margin-right: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-0 {
  margin-left: 0;
}

.m-8 {
  margin: 8px;
}

.mt-8 {
  margin-top: 8px;
}

.mr-8 {
  margin-right: 8px;
}

.mb-8 {
  margin-bottom: 8px;
}

.ml-8 {
  margin-left: 8px;
}

.m-16 {
  margin: 16px;
}

.mt-16 {
  margin-top: 16px;
}

.mr-16 {
  margin-right: 16px;
}

.mb-16 {
  margin-bottom: 16px;
}

.ml-16 {
  margin-left: 16px;
}

.m-24 {
  margin: 24px;
}

.mt-24 {
  margin-top: 24px;
}

.mr-24 {
  margin-right: 24px;
}

.mb-24 {
  margin-bottom: 24px;
}

.ml-24 {
  margin-left: 24px;
}

.m-32 {
  margin: 32px;
}

.mt-32 {
  margin-top: 32px;
}

.mr-32 {
  margin-right: 32px;
}

.mb-32 {
  margin-bottom: 32px;
}

.ml-32 {
  margin-left: 32px;
}

.m-40 {
  margin: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.m-48 {
  margin: 48px;
}

.mt-48 {
  margin-top: 48px;
}

.mr-48 {
  margin-right: 48px;
}

.mb-48 {
  margin-bottom: 48px;
}

.ml-48 {
  margin-left: 48px;
}

.m-56 {
  margin: 56px;
}

.mt-56 {
  margin-top: 56px;
}

.mr-56 {
  margin-right: 56px;
}

.mb-56 {
  margin-bottom: 56px;
}

.ml-56 {
  margin-left: 56px;
}

.m-64 {
  margin: 64px;
}

.mt-64 {
  margin-top: 64px;
}

.mr-64 {
  margin-right: 64px;
}

.mb-64 {
  margin-bottom: 64px;
}

.ml-64 {
  margin-left: 64px;
}

.p-0 {
  padding: 0;
}

.pt-0 {
  padding-top: 0;
}

.pr-0 {
  padding-right: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pl-0 {
  padding-left: 0;
}

.p-8 {
  padding: 8px;
}

.pt-8 {
  padding-top: 8px;
}

.pr-8 {
  padding-right: 8px;
}

.pb-8 {
  padding-bottom: 8px;
}

.pl-8 {
  padding-left: 8px;
}

.p-16 {
  padding: 16px;
}

.pt-16 {
  padding-top: 16px;
}

.pr-16 {
  padding-right: 16px;
}

.pb-16 {
  padding-bottom: 16px;
}

.pl-16 {
  padding-left: 16px;
}

.p-24 {
  padding: 24px;
}

.pt-24 {
  padding-top: 24px;
}

.pr-24 {
  padding-right: 24px;
}

.pb-24 {
  padding-bottom: 24px;
}

.pl-24 {
  padding-left: 24px;
}

.p-32 {
  padding: 32px;
}

.pt-32 {
  padding-top: 32px;
}

.pr-32 {
  padding-right: 32px;
}

.pb-32 {
  padding-bottom: 32px;
}

.pl-32 {
  padding-left: 32px;
}

.p-40 {
  padding: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.pr-40 {
  padding-right: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pl-40 {
  padding-left: 40px;
}

.p-48 {
  padding: 48px;
}

.pt-48 {
  padding-top: 48px;
}

.pr-48 {
  padding-right: 48px;
}

.pb-48 {
  padding-bottom: 48px;
}

.pl-48 {
  padding-left: 48px;
}

.p-56 {
  padding: 56px;
}

.pt-56 {
  padding-top: 56px;
}

.pr-56 {
  padding-right: 56px;
}

.pb-56 {
  padding-bottom: 56px;
}

.pl-56 {
  padding-left: 56px;
}

.p-64 {
  padding: 64px;
}

.pt-64 {
  padding-top: 64px;
}

.pr-64 {
  padding-right: 64px;
}

.pb-64 {
  padding-bottom: 64px;
}

.pl-64 {
  padding-left: 64px;
}

.sr .has-animations .is-revealing {
  visibility: hidden;
}

.input, .textarea {
  color: #6b7a90;
  background-color: #182538;
  width: 100%;
  max-width: 100%;
}

.input::placeholder, .textarea::placeholder {
  color: #6b7a90;
}

.input::-moz-placeholder {
  color: #6b7a90;
}

.textarea::-moz-placeholder {
  color: #6b7a90;
}

:is(.input:-ms-input-placeholder, .textarea:-ms-input-placeholder) {
  color: #6b7a90;
}

.input:hover, .textarea:hover {
  border-color: #d9e0ea;
}

.input:active, .input:focus, .textarea:active, .textarea:focus {
  border-color: #e9edf3;
  outline: none;
}

.input[disabled], .textarea[disabled] {
  cursor: not-allowed;
  background-color: #f8f9fb;
  border-color: #f8f9fb;
}

.input {
  -webkit-appearance: none;
  -moz-appearance: none;
  letter-spacing: -.1px;
  box-shadow: none;
  padding: 13px 16px;
  font-size: 16px;
  line-height: 20px;
}

.input .inline-input {
  width: auto;
  display: inline;
}

.textarea {
  resize: vertical;
  min-width: 100%;
  display: block;
}

.textarea .inline-textarea {
  width: auto;
  display: inline;
}

.field-grouped > .control:not(:last-child) {
  margin-bottom: 8px;
}

.switch[type="checkbox"] {
  -webkit-user-select: none;
  user-select: none;
  opacity: 0;
  outline: 0;
  position: absolute;
}

.switch[type="checkbox"] + label {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  padding-left: 78px;
  line-height: 36px;
  display: inline-flex;
  position: relative;
}

.switch[type="checkbox"] + label:before, .switch[type="checkbox"] + label:after {
  content: "";
  display: block;
  position: absolute;
}

.switch[type="checkbox"] + label:before {
  background: #e9edf3;
  border-radius: 18px;
  width: 64px;
  height: 36px;
  top: 0;
  left: 0;
}

.switch[type="checkbox"] + label:after {
  background: #fff;
  border-radius: 12px;
  width: 24px;
  height: 24px;
  transition: all .15s ease-out;
  top: 6px;
  left: 6px;
}

.switch[type="checkbox"]:checked + label:after {
  left: 34px;
}

@media (width >= 641px) {
  .field-grouped {
    display: flex;
  }

  .field-grouped > .control {
    flex-shrink: 0;
  }

  .field-grouped > .control.control-expanded {
    flex-grow: 1;
    flex-shrink: 1;
  }

  .field-grouped > .control:not(:last-child) {
    margin-bottom: 0;
    margin-right: 8px;
  }
}

.button {
  letter-spacing: 0;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  background-color: #e9edf3;
  border: none;
  border-radius: 2px;
  justify-content: center;
  height: 48px;
  padding: 16px 32px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  display: inline-flex;
  color: #535fd7 !important;
  text-decoration: none !important;
}

.button:hover {
  background: #ecf0f5;
}

.button:active {
  outline: 0;
}

.button:before {
  border-radius: 2px;
}

.button-shadow {
  position: relative;
}

.button-shadow:before {
  content: "";
  mix-blend-mode: multiply;
  transition: box-shadow .15s;
  position: absolute;
  inset: 0;
  box-shadow: 0 8px 24px #ffffff40;
}

.button-shadow:hover:before {
  box-shadow: 0 8px 24px #ffffff59;
}

.button-sm {
  height: 32px;
  padding: 8px 24px;
}

.button-sm.button-shadow:before {
  box-shadow: 0 4px 16px #ffffff40;
}

.button-sm.button-shadow:hover:before {
  box-shadow: 0 4px 16px #ffffff59;
}

.button-primary {
  background-color: #535fd7;
  color: #fff !important;
}

.button-primary:hover {
  background: #5763d8;
}

.button-primary.button-shadow:before {
  mix-blend-mode: normal;
  box-shadow: 0 8px 16px #535fd740;
}

.button-primary.button-shadow:hover:before {
  box-shadow: 0 8px 16px #535fd759;
}

.button-primary .button-sm.button-shadow:before {
  box-shadow: 0 4px 16px #535fd740;
}

.button-primary .button-sm.button-shadow:hover:before {
  box-shadow: 0 4px 16px #535fd759;
}

.button-block {
  display: flex;
}

.site-header {
  z-index: 2;
  padding: 24px 0;
  position: relative;
}

.site-header:before {
  content: "";
  background-image: url("header-illustration-light.37da4125.svg");
  width: 1440px;
  height: 324px;
  position: absolute;
  top: -140px;
  right: -100px;
}

.site-header-inner {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.header-links, .header-links li {
  display: inline-flex;
}

.header-links a:not(.button) {
  letter-spacing: -.1px;
  color: #8595ae;
  text-transform: uppercase;
  padding: 8px 24px;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-decoration: none;
}

.header-links a:not(.button):hover, .header-links a:not(.button):active {
  color: #fff;
}

@media (width >= 641px) {
  .site-header:before {
    display: none;
  }
}

.hero {
  text-align: center;
  padding-top: 48px;
  padding-bottom: 88px;
}

.hero-copy {
  position: relative;
}

.hero-paragraph {
  margin-bottom: 32px;
}

.hero-cta {
  max-width: 400px;
  margin-bottom: 80px;
  margin-left: auto;
  margin-right: auto;
}

.lights-toggle {
  color: #6b7a90a3;
}

.header-illustration {
  display: none;
}

.header-illustration img, .header-illustration svg {
  max-width: none;
}

.header-illustration-image {
  display: none;
}

.hero-media {
  width: 100%;
  position: relative;
}

.hero-media-illustration {
  position: absolute;
  top: -10%;
  left: 15px;
}

.hero-media-illustration img, .hero-media-illustration svg {
  max-width: 136%;
}

.hero-media-container {
  position: relative;
}

.hero-media-image {
  border-radius: 4px;
  margin: 0 auto;
  transform: perspective(1000px)rotateY(16deg)rotateX(2deg)rotateZ(-7deg)scaleY(.95)translateX(2%);
  box-shadow: 48px 16px 48px #1825381f;
}

.lights-off .hero-media-image {
  box-shadow: 48px 16px 48px #0003;
}

.step-list {
  margin-bottom: 10px;
  list-style: none;
}

.step-list p {
  margin-bottom: 0;
}

.second-feature-header {
  margin-top: 50px;
}

@media (width <= 639px) {
  .hero-cta > * {
    display: flex;
  }

  .hero-cta > * + * {
    margin-top: 32px;
  }

  .lights-toggle {
    justify-content: center;
  }
}

@media (width >= 641px) {
  .hero {
    text-align: left;
    padding-top: 88px;
    padding-bottom: 120px;
  }

  .hero-inner {
    justify-content: space-between;
    display: flex;
  }

  .hero-copy {
    z-index: 1;
    min-width: 448px;
    max-width: 512px;
    padding-top: 40px;
    padding-right: 48px;
  }

  .hero-title {
    margin-bottom: 16px;
  }

  .hero-paragraph {
    margin-bottom: 32px;
  }

  .hero-cta {
    align-items: center;
    margin: 0;
    display: flex;
  }

  .hero-cta .button:first-child {
    margin-right: 32px;
  }

  .header-illustration {
    display: block;
  }

  .hero-media {
    z-index: 0;
  }

  .hero-media img, .hero-media svg {
    max-width: none;
  }

  .header-illustration-image {
    width: 1440px;
    height: 324px;
    display: block;
    position: absolute;
    top: -175px;
    left: -692px;
  }
}

.features-wrap {
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 700px;
  margin: 0 auto;
  display: flex;
}

.features-image {
  margin-top: 64px;
  margin-bottom: 112px;
  position: relative;
}

.features-illustration {
  max-width: 136%;
  position: absolute;
  top: -2%;
  left: 50%;
  transform: translateX(-50%);
}

.features-box {
  margin: 0 auto;
  transform: perspective(1000px)rotateY(10deg)translateY(2%);
  box-shadow: 48px 16px 48px #1825381f;
}

.lights-off .features-box {
  box-shadow: 48px 16px 48px #0003;
}

.feature {
  text-align: center;
  width: calc(50% - 20px);
  margin-bottom: 48px;
}

.feature:last-of-type {
  margin-bottom: 0;
}

.feature-icon {
  margin-bottom: 16px;
  display: inline-flex;
}

.feature-icon img, .feature-icon svg {
  max-width: none;
}

.feature-title {
  margin-bottom: 26px;
  position: relative;
}

.feature-title:after {
  content: "";
  background: #e9edf3;
  width: 32px;
  height: 2px;
  position: absolute;
  bottom: -14px;
  left: calc(50% - 16px);
}

.lights-off .feature-title:after {
  background: #304057;
}

#plans {
  font-family: Poppins, sans-serif;
}

.pricing-wrapper {
  width: 100%;
  max-width: 1000px;
}

.plan-feature li {
  color: #3b3b3b;
  background-image: url("tick.1538a2ce.svg");
  background-position: 0 4px;
  background-repeat: no-repeat;
  padding-left: 36px;
  font-weight: 400;
}

.mask-box {
  box-shadow: none;
  background: #fff;
  border: 1px solid #e1e4ef;
  border-radius: 30px;
  position: relative;
}

.mask-box .mask {
  background: #243060;
  border-radius: 30px;
  width: 100px;
  height: 48px;
  transition: all .5s;
  position: absolute;
}

.mask-box .MuiButton-root {
  text-transform: capitalize;
  border-radius: 30px;
  width: 100px;
  height: 48px;
  font-weight: normal;
  transition: all .2s .1s;
}

.mask-box .MuiButton-root:hover {
  opacity: .8;
}

@media (width >= 641px) {
  .features {
    position: relative;
  }

  .features .section-inner {
    padding-bottom: 100px;
  }

  .features .section-paragraph {
    padding-left: 72px;
    padding-right: 72px;
  }

  .features:before {
    content: "";
    background: linear-gradient(#535fd700, #535fd70a);
    width: 100%;
    height: 300px;
    position: absolute;
    top: 168px;
    left: 0;
  }

  .lights-off .features:before {
    display: none;
  }

  .feature {
    text-align: left;
  }

  .feature-inner {
    display: flex;
  }

  .feature-icon {
    margin-top: 8px;
    margin-bottom: 0;
    margin-right: 32px;
    display: block;
  }

  .feature-title:after {
    left: 0;
  }
}

.cta {
  position: relative;
}

.cta .section-inner {
  padding-bottom: 64px;
}

.cta .section-paragraph {
  margin-bottom: 32px;
}

.cta:before {
  content: "";
  background-image: url("cta-illustration-light.10d7b2c8.svg");
  width: 1440px;
  height: 263px;
  position: absolute;
  bottom: -32px;
  left: calc(50% - 720px);
}

.cta-cta {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

@media (width <= 639px) {
  .cta-cta .button {
    display: flex;
  }

  .feature {
    width: 100%;
  }
}

@media (width >= 641px) {
  .cta .section-inner {
    padding-bottom: 128px;
  }

  .cta .section-paragraph {
    margin-bottom: 40px;
    padding-left: 72px;
    padding-right: 72px;
  }

  .cta:before {
    bottom: 0;
  }

  .cta-cta .button {
    min-width: 170px;
  }
}

.is-boxed {
  background: #e9edf3;
}

.body-wrap {
  background: #fff;
  flex-direction: column;
  min-height: 100vh;
  display: flex;
  overflow: hidden;
}

.boxed-container {
  max-width: 1440px;
  margin: 0 auto;
  box-shadow: 0 16px 48px #ffffff80;
}

main {
  flex: 1 0 auto;
}

.section-inner {
  padding-top: 48px;
  padding-bottom: 48px;
  position: relative;
}

@media (width >= 641px) {
  .section-inner {
    padding-top: 88px;
    padding-bottom: 88px;
  }
}

.site-footer {
  letter-spacing: 0;
  color: #6b7a90;
  background: #182538;
  font-size: 14px;
  line-height: 20px;
}

.site-footer a {
  color: #6b7a90;
  text-decoration: none;
}

.site-footer a:hover, .site-footer a:active {
  text-decoration: underline;
}

.site-footer:before {
  display: none;
}

.lights-off .site-footer:before {
  display: block;
}

.site-footer-inner {
  flex-wrap: wrap;
  padding-top: 48px;
  padding-bottom: 48px;
  display: flex;
  position: relative;
}

.footer-brand, .footer-links, .footer-social-links, .footer-copyright {
  flex: none;
  justify-content: center;
  width: 100%;
  display: inline-flex;
}

.footer-brand, .footer-links, .footer-social-links {
  margin-bottom: 24px;
}

.footer-links li + li, .footer-social-links li + li {
  margin-left: 16px;
}

.footer-social-links li {
  display: inline-flex;
}

.footer-social-links li a {
  padding: 8px;
}

@media (width >= 641px) {
  .site-footer-inner {
    justify-content: space-between;
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .footer-brand, .footer-links, .footer-social-links, .footer-copyright {
    flex: 50%;
  }

  .footer-brand, .footer-copyright {
    justify-content: flex-start;
  }

  .footer-links, .footer-social-links {
    justify-content: flex-end;
  }

  .footer-links {
    order: 1;
    margin-bottom: 0;
  }
}

.switch[type="checkbox"] + label:before {
  background: linear-gradient(to right, #2feafc 0, #535fd7 100%);
}

.switch[type="checkbox"] + label:after {
  background: #fff;
}

.switch[type="checkbox"]:checked + label:before {
  background: linear-gradient(to right, #ffbc48 0, #fe7e1f 100%);
}

.asset-light, .asset-dark {
  visibility: hidden;
  opacity: 0;
}

.is-loaded .asset-light, .is-loaded .asset-dark {
  visibility: visible;
  opacity: 1;
}

.asset-dark {
  display: none;
}

.lights-off {
  color: #8595ae;
}

.lights-off .asset-light {
  display: none;
}

.lights-off .asset-dark {
  display: block;
}

.lights-off a {
  color: #8595ae;
}

.lights-off h1, .lights-off h2, .lights-off h3, .lights-off h4, .lights-off h5, .lights-off h6, .lights-off .h1, .lights-off .h2, .lights-off .h3, .lights-off .h4, .lights-off .h5, .lights-off .h6 {
  color: #fff !important;
}

.lights-off.is-boxed {
  background: #202d3f;
}

.lights-off .body-wrap {
  background: #182538;
}

.lights-off .boxed-container {
  box-shadow: 0 16px 48px #0003;
}

.lights-off hr {
  background: #304057;
  height: 1px;
  display: block;
}

.lights-off .has-top-divider {
  position: relative;
}

.lights-off .has-top-divider:before {
  content: "";
  background: #304057;
  width: 100%;
  height: 1px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.lights-off .has-bottom-divider {
  position: relative;
}

.lights-off .has-bottom-divider:after {
  content: "";
  background: #304057;
  width: 100%;
  height: 1px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

body, a, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  transition: color .25s;
}

.is-boxed, .body-wrap, .has-top-divider, .has-bottom-divider:after, hr:after, .feature-title:after {
  transition: background .25s;
}

.boxed-container {
  transition: box-shadow .15s;
}

@keyframes fadeInLogo {
  0% {
    opacity: 0;
    transform: scale(.5);
  }
}

@keyframes fadeInLeftMedia {
  0% {
    opacity: 0;
    transform: scale(.8)translate3d(40px, 10px, 0);
  }

  30% {
    opacity: 1;
  }
}

@keyframes fadeInLeftIllustration {
  0% {
    opacity: 0;
    transform: scale(.95)translate3d(40px, 10px, 0);
  }
}

@keyframes fadeUpBox {
  0% {
    opacity: 0;
    transform: scale(.95)translate3d(-40px, 20px, 0);
  }

  30% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
}

.is-loaded .header-logo-image {
  will-change: transform;
  animation: .35s cubic-bezier(.3, 0, .2, 1) both fadeInLogo;
}

.is-loaded .hero-media-image {
  will-change: transform;
  animation: 1s cubic-bezier(.3, 0, .2, 1) .2s both fadeInLeftMedia;
}

.is-loaded .hero-media-illustration-image {
  will-change: transform;
  animation: 1s cubic-bezier(.3, 0, .2, 1) .2s both fadeInLeftIllustration;
}

.is-loaded .features-box {
  will-change: transform;
  animation: 1s cubic-bezier(.3, 0, .2, 1) .2s both fadeUpBox;
}

.is-loaded .header-illustration-image {
  will-change: transform;
  animation: 1s cubic-bezier(.3, 0, .2, 1) both fadeIn;
}

.is-loaded .features-illustration {
  will-change: transform;
  animation: 1s cubic-bezier(.3, 0, .2, 1) .2s both fadeIn;
}

.stepform-wrapper {
  z-index: 20;
  position: fixed;
  inset: 0;
}

.wrapper-blur {
  -webkit-filter: blur(10px);
}

#queryForm {
  z-index: 3;
  color: #fff;
  background-color: #535fd7;
  border-radius: 20px;
  width: 70%;
  min-width: 300px;
  max-width: 768px;
  max-height: 95vh;
  margin: 100px auto;
  padding: 20px 40px 100px;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  overflow: auto;
}

#queryForm p {
  margin-bottom: 10px;
}

.progress {
  text-align: center;
  margin: 20px 0;
}

.input input, .input textarea, .input-field input, .input-field textarea {
  color: #54657f;
  border: none;
  border-radius: 6px;
  width: 100%;
  padding: 10px;
  font-size: 17px;
}

input::placeholder, textarea::placeholder {
  color: #54657f;
  opacity: 1;
}

textarea::placeholder {
  color: #54657f;
}

input::-moz-placeholder {
  color: #54657f;
}

input[type="radio"] {
  width: 25px;
  display: inline-block;
}

input:focus-visible, textarea:focus-visible {
  outline: none;
}

input.invalid {
  background-color: #fff;
  border: 2px solid red;
}

.step {
  opacity: .5;
  background-color: #333d5b;
  border: none;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin: 0 2px;
  display: inline-block;
}

.step.active {
  opacity: 1;
}

.step.finish {
  background-color: #04aa6d;
}

.btn-info {
  text-align: center;
  color: #fff;
  opacity: 1;
  cursor: pointer;
  z-index: 4;
  background: none;
  border: 1px solid #fff;
  border-radius: 6px;
  outline: none;
  width: 113px;
  padding: 10px 5px;
  display: inline-block;
}

.error-message {
  color: #fff;
  background: #ec6f6f;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 10px 12px;
}

.info-message {
  flex-direction: row;
  align-items: center;
  margin: 20px;
  display: flex;
}

.info-message :global(.spinner) {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  display: inline-block;
}

.actions {
  margin-top: 20px;
  position: relative;
}

.input-field label {
  cursor: pointer;
}

.question {
  margin-bottom: 50px;
}

.btn-info:first-child {
  margin-right: 15px;
}

.input label {
  color: #fff;
}

.input textarea, .input-field textarea {
  resize: none;
  max-height: 150px;
}

.closeForm {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.not-found {
  background-color: #243060;
  height: 100vh;
}

.not-found h1 {
  border-bottom: 1px dotted #fff;
  font-size: 150px;
  line-height: 1;
}

.not-found button:hover {
  color: #243060 !important;
}

@media only screen and (width <= 768px) {
  #queryForm {
    margin: 50px auto;
  }

  button.back-to-dashboard {
    width: 32px;
    min-width: 32px;
    padding: 6px 8px;
  }

  button.back-to-dashboard p {
    display: none;
  }

  button.back-to-dashboard .MuiButton-startIcon {
    margin: 0;
  }
}

@media screen and (width <= 744px) {
  .siteImageUrl {
    min-height: 160px;
  }
}

@media only screen and (height <= 577px) {
  #queryForm {
    -ms-overflow-style: none;
    scrollbar-width: none;
    border-radius: 0;
    min-width: 100%;
    min-height: 100%;
    margin: 0;
    position: relative;
  }

  #queryForm::-webkit-scrollbar {
    display: none;
  }
}

button.back-to-dashboard {
  width: auto;
}

button.back-to-dashboard p {
  text-transform: none;
}

.ql-editor {
  min-height: 400px;
}

.ql-snow .ql-picker-label {
  display: flex !important;
}

.height-minus-140 {
  height: calc(100vh - 140px);
}

.blank-image-bg {
  background-image: url("image-empty-background.5f7e9b56.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#app .hover\:cursor-pointer:hover {
  cursor: pointer;
}

#app .hover\:border-catelina-blue:hover {
  --tw-border-opacity: 1;
  border-color: rgb(36 48 96 / var(--tw-border-opacity, 1));
}

#app .hover\:border-gray-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
}

#app .hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity, 1));
}

#app .hover\:bg-catelina-blue:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(36 48 96 / var(--tw-bg-opacity, 1));
}

#app .hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}

#app .hover\:bg-gray-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity, 1));
}

#app .hover\:bg-grey-primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(59 59 59 / var(--tw-bg-opacity, 1));
}

#app .hover\:bg-neutral-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(212 212 212 / var(--tw-bg-opacity, 1));
}

#app .hover\:bg-red-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity, 1));
}

#app .hover\:bg-red-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity, 1));
}

#app .hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

#app .hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

#app .hover\:opacity-80:hover {
  opacity: .8;
}

#app .hover\:shadow-xl:hover {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#app .focus\:border-blue-300:focus {
  --tw-border-opacity: 1;
  border-color: rgb(147 197 253 / var(--tw-border-opacity, 1));
}

#app .focus\:border-blue-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity, 1));
}

#app .focus\:border-indigo-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity, 1));
}

#app .focus\:border-red-300:focus {
  --tw-border-opacity: 1;
  border-color: rgb(252 165 165 / var(--tw-border-opacity, 1));
}

#app .focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

#app .focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

#app .focus\:ring-1:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

#app .focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

#app .focus\:ring-blue-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity, 1));
}

#app .focus\:ring-catelina-blue:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(36 48 96 / var(--tw-ring-opacity, 1));
}

#app .focus\:ring-gray-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity, 1));
}

#app .focus\:ring-indigo-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity, 1));
}

#app .focus\:ring-neutral-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(212 212 212 / var(--tw-ring-opacity, 1));
}

#app .focus\:ring-opacity-50:focus {
  --tw-ring-opacity: .5;
}

@media (width >= 474px) {
  #app .sm\:my-1\.5 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  #app .sm\:mb-0 {
    margin-bottom: 0;
  }

  #app .sm\:mb-8 {
    margin-bottom: 2rem;
  }

  #app .sm\:ml-0 {
    margin-left: 0;
  }

  #app .sm\:ml-2 {
    margin-left: .5rem;
  }

  #app .sm\:mt-0 {
    margin-top: 0;
  }

  #app .sm\:mt-6 {
    margin-top: 1.5rem;
  }

  #app .sm\:mt-8 {
    margin-top: 2rem;
  }

  #app .sm\:block {
    display: block;
  }

  #app .sm\:hidden {
    display: none;
  }

  #app .sm\:h-\[50px\] {
    height: 50px;
  }

  #app .sm\:h-\[64px\] {
    height: 64px;
  }

  #app .sm\:h-auto {
    height: auto;
  }

  #app .sm\:w-2\/5 {
    width: 40%;
  }

  #app .sm\:w-3\/5 {
    width: 60%;
  }

  #app .sm\:w-32 {
    width: 8rem;
  }

  #app .sm\:w-40 {
    width: 10rem;
  }

  #app .sm\:w-80 {
    width: 20rem;
  }

  #app .sm\:w-auto {
    width: auto;
  }

  #app .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  #app .sm\:flex-row {
    flex-direction: row;
  }

  #app .sm\:items-center {
    align-items: center;
  }

  #app .sm\:justify-between {
    justify-content: space-between;
  }

  #app .sm\:gap-4 {
    gap: 1rem;
  }

  #app .sm\:border {
    border-width: 1px;
  }

  #app .sm\:p-4 {
    padding: 1rem;
  }

  #app .sm\:p-8 {
    padding: 2rem;
  }

  #app .sm\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  #app .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  #app .sm\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  #app .sm\:pb-8 {
    padding-bottom: 2rem;
  }

  #app .sm\:text-base {
    font-size: 16px;
  }

  #app .sm\:text-sm {
    font-size: 14px;
  }
}

@media (width >= 744px) {
  #app .md\:static {
    position: static;
  }

  #app .md\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  #app .md\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  #app .md\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  #app .md\:mb-0 {
    margin-bottom: 0;
  }

  #app .md\:ml-4 {
    margin-left: 1rem;
  }

  #app .md\:ml-5 {
    margin-left: 1.25rem;
  }

  #app .md\:mr-10 {
    margin-right: 2.5rem;
  }

  #app .md\:mr-4 {
    margin-right: 1rem;
  }

  #app .md\:mr-5 {
    margin-right: 1.25rem;
  }

  #app .md\:mt-0 {
    margin-top: 0;
  }

  #app .md\:mt-10 {
    margin-top: 2.5rem;
  }

  #app .md\:mt-8 {
    margin-top: 2rem;
  }

  #app .md\:block {
    display: block;
  }

  #app .md\:inline-block {
    display: inline-block;
  }

  #app .md\:inline {
    display: inline;
  }

  #app .md\:flex {
    display: flex;
  }

  #app .md\:inline-flex {
    display: inline-flex;
  }

  #app .md\:hidden {
    display: none;
  }

  #app .md\:h-6 {
    height: 1.5rem;
  }

  #app .md\:h-\[100\%\] {
    height: 100%;
  }

  #app .md\:h-\[200px\] {
    height: 200px;
  }

  #app .md\:h-full {
    height: 100%;
  }

  #app .md\:min-h-0 {
    min-height: 0;
  }

  #app .md\:w-20 {
    width: 5rem;
  }

  #app .md\:w-3 {
    width: .75rem;
  }

  #app .md\:w-3\/12 {
    width: 25%;
  }

  #app .md\:w-48 {
    width: 12rem;
  }

  #app .md\:w-52 {
    width: 13rem;
  }

  #app .md\:w-64 {
    width: 16rem;
  }

  #app .md\:w-80 {
    width: 20rem;
  }

  #app .md\:w-9\/12 {
    width: 75%;
  }

  #app .md\:w-\[36px\] {
    width: 36px;
  }

  #app .md\:w-auto {
    width: auto;
  }

  #app .md\:w-full {
    width: 100%;
  }

  #app .md\:max-w-md {
    max-width: 28rem;
  }

  #app .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  #app .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  #app .md\:flex-row {
    flex-direction: row;
  }

  #app .md\:flex-col {
    flex-direction: column;
  }

  #app .md\:justify-end {
    justify-content: flex-end;
  }

  #app .md\:gap-0 {
    gap: 0;
  }

  #app .md\:p-4 {
    padding: 1rem;
  }

  #app .md\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  #app .md\:pl-\[260px\] {
    padding-left: 260px;
  }

  #app .md\:pl-\[280px\] {
    padding-left: 280px;
  }

  #app .md\:text-base-2 {
    font-size: 18px;
  }

  #app .md\:text-lg {
    font-size: 20px;
  }

  #app .md\:text-sm {
    font-size: 14px;
  }

  #app .md\:text-xsm {
    font-size: 12px;
  }

  #app .md\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  }

  #app .md\:shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

@media (width >= 1024px) {
  #app .lg\:top-auto {
    top: auto;
  }

  #app .lg\:m-0 {
    margin: 0;
  }

  #app .lg\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  #app .lg\:mb-0 {
    margin-bottom: 0;
  }

  #app .lg\:ml-10 {
    margin-left: 2.5rem;
  }

  #app .lg\:ml-2 {
    margin-left: .5rem;
  }

  #app .lg\:ml-3 {
    margin-left: .75rem;
  }

  #app .lg\:ml-4 {
    margin-left: 1rem;
  }

  #app .lg\:mr-2 {
    margin-right: .5rem;
  }

  #app .lg\:mr-3 {
    margin-right: .75rem;
  }

  #app .lg\:mt-0 {
    margin-top: 0;
  }

  #app .lg\:mt-16 {
    margin-top: 4rem;
  }

  #app .lg\:block {
    display: block;
  }

  #app .lg\:flex {
    display: flex;
  }

  #app .lg\:hidden {
    display: none;
  }

  #app .lg\:h-80 {
    height: 20rem;
  }

  #app .lg\:max-h-24 {
    max-height: 6rem;
  }

  #app .lg\:w-1\/2 {
    width: 50%;
  }

  #app .lg\:w-4\/5 {
    width: 80%;
  }

  #app .lg\:w-auto {
    width: auto;
  }

  #app .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  #app .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  #app .lg\:flex-row {
    flex-direction: row;
  }

  #app .lg\:items-baseline {
    align-items: baseline;
  }

  #app .lg\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  #app .lg\:pb-0 {
    padding-bottom: 0;
  }
}

@media (width >= 1280px) {
  #app .xl\:block {
    display: block;
  }

  #app .xl\:hidden {
    display: none;
  }

  #app .xl\:w-60 {
    width: 15rem;
  }

  #app .xl\:w-64 {
    width: 16rem;
  }

  #app .xl\:w-8\/12 {
    width: 66.6667%;
  }

  #app .xl\:w-full {
    width: 100%;
  }

  #app .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  #app .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  #app .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  #app .xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  #app .xl\:pl-\[340px\] {
    padding-left: 340px;
  }

  #app .xl\:text-right {
    text-align: right;
  }
}

@media (prefers-color-scheme: dark) {
  #app .dark\:border-catelina-blue {
    --tw-border-opacity: 1;
    border-color: rgb(36 48 96 / var(--tw-border-opacity, 1));
  }

  #app .dark\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgb(75 85 99 / var(--tw-border-opacity, 1));
  }

  #app .dark\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity, 1));
  }

  #app .dark\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgb(153 27 27 / var(--tw-border-opacity, 1));
  }

  #app .dark\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
  }

  #app .dark\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
  }

  #app .dark\:text-catelina-blue {
    --tw-text-opacity: 1;
    color: rgb(36 48 96 / var(--tw-text-opacity, 1));
  }

  #app .dark\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  }

  #app .dark\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgb(248 113 113 / var(--tw-text-opacity, 1));
  }

  #app .dark\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  }

  #app .dark\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(156 163 175 / var(--tw-placeholder-opacity, 1));
  }

  #app .dark\:hover\:text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgb(209 213 219 / var(--tw-text-opacity, 1));
  }

  #app .dark\:focus\:border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgb(59 130 246 / var(--tw-border-opacity, 1));
  }

  #app .dark\:focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity, 1));
  }
}
/*# sourceMappingURL=index.f471a571.css.map */
